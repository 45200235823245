export default {
  addChannel (monitorId, channel) {
    return axios.post(`/monitors/${monitorId}/notifications/slack`, {
      channel
    })
  },

  storeWebhook (monitorId, webhook) {
    return axios.post(`/monitors/${monitorId}/notifications/slack-webhook`, {
      webhook_url: webhook
    })
  },

  generalStoreWebhook (webhook) {
    return axios.post(`/monitors/general/notifications/slack-webhook`, {
      webhook_url: webhook
    })
  },

  bulkStoreWebhook (monitors, webhook_url) {
    return axios.post(`/monitors/bulk/notifications/slack-webhook`, {
      monitors,
      webhook_url
    })
  }
}
