<template>
  <div class="MonitorActions-toolbar">
    <div class="MonitorActions">
      <div ref="trigger">
        <base-button
          class="button"
          color="primary"
          icon="IconButtonArrowDown"
          :icon-width="12"
          :icon-height="7"
        >
          Bulk Actions
        </base-button>
      </div>
      <div ref="dropdown">
        <nav class="nav">
          <a href="#" class="nav-item" v-if="user.role !== role.Viewer && user.role !== role.Accountant"
             @click.prevent="openTagsModal">Add tags</a>
          <a href="#" class="nav-item" @click.prevent="pause">Pause</a>
          <a href="#" class="nav-item" @click.prevent="run">Start</a>
          <a href="#" class="nav-item" @click.prevent="deleteMonitors">Delete</a>
          <a href="#" class="nav-item" @click.prevent="resetMonitors">Reset</a>
          <a href="#" class="nav-item" ref="bulk-notifications">Notifications
            <span class="arrow-icon">→</span>
          </a>
        </nav>
      </div>
      <div ref="dropdown-notifications">
        <nav class="nav">
          <a href="#" class="nav-item" @click.prevent="email">Email</a>
          <a href="#" class="nav-item" @click.prevent="smsCalls">SMS/Calls</a>
          <a href="#" class="nav-item" @click.prevent="slack">Slack</a>
          <a href="#" class="nav-item" @click.prevent="telegram">Telegram</a>
          <a href="#" class="nav-item" @click.prevent="twilioSms">Twilio SMS</a>
          <a href="#" class="nav-item" @click.prevent="twilioVoice">Twilio Voice</a>
          <a href="#" class="nav-item" @click.prevent="webhook">Webhook</a>
          <a href="#" class="nav-item" @click.prevent="signl4">SIGNL4</a>
          <a href="#" class="nav-item" @click.prevent="discord">Discord</a>
          <a href="#" class="nav-item" @click.prevent="teams">Teams</a>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js'
import monitorsApi from '@/api/monitorsApi.js'
import swal from 'sweetalert2'
import { Role } from '@/_helpers/role'
import emailApi from '@/api/monitorNotifications/emailApi'
import phoneNumbersApi from '@/api/monitorNotifications/phoneNumbersApi'
import slackApi from '@/api/monitorNotifications/slackApi'
import smsApi from '@/api/monitorNotifications/smsApi'
import voiceApi from '@/api/monitorNotifications/voiceApi'
import webhookApi from '@/api/monitorNotifications/webhookApi'
import signl4Api from '@/api/monitorNotifications/signl4Api'
import discordApi from '@/api/monitorNotifications/discordApi'
import msTeamsApi from '@/api/monitorNotifications/msTeamsApi'

const botId = process.env.VUE_APP_TELEGRAM_BOT_ID
export default {
  props: {
    selectedMonitors: {
      required: true,
      type: Array
    }
  },

  data () {
    return {
      tippyInstance: null,
      open: false,
      action: null,
      role: Role
    }
  },

  mounted () {
    this.initDropdown()
  },

  methods: {
    initDropdown () {
      this.tippyInstance = tippy(this.$refs.trigger, {
        content: this.$refs.dropdown,
        interactive: true,
        theme: 'light-border',
        animation: 'shift-away-subtle',
        placement: 'top-end',
        arrow: true,
        inertia: true,
        trigger: 'click',
        maxWidth: 'none',

        onShow: () => {
          this.open = true
        },

        onHide: () => {
          this.open = false
        }
      })
      this.tippyInstance = tippy(this.$refs['bulk-notifications'], {
        content: this.$refs['dropdown-notifications'],
        interactive: true,
        theme: 'light-border',
        // animation: 'shift-away-subtle',
        placement: 'right-end',
        // arrow: true,
        inertia: true,
        trigger: 'mouseenter',
        maxWidth: 'none',

        onShow: () => {
          this.open = true
        },

        onHide: () => {
          this.open = false
        }
      })
    },
    async pause () {
      this.tippyInstance.hide()

      await monitorsApi.multiplePause(this.selectedMonitors.map(item => item.id))

      this.$emit('pause')
    },

    async run () {
      if (this.user.subscription_paused) {
        return false
      }

      this.tippyInstance.hide()

      let response = await monitorsApi.multipleRun(this.selectedMonitors.map(item => item.id))

      if (response && !response.success && response.message) {
        swal.fire({
          html: '<h4>' + response.message + '</h4>',
          title: '',
          showClass: {
            popup: ''
          },
          hideClass: {
            popup: ''
          },
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonColor: '#9ca3a1',
          cancelButtonText: 'Ok'
        })
      }

      this.$emit('run')
    },

    async deleteMonitors () {
      swal.fire({
        html: '<h4>' + 'Are you sure that you want to delete the selected monitors?</h4>',
        title: '',
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        showCancelButton: true,
        confirmButtonColor: '#eb5757',
        cancelButtonColor: '#9ca3a1',
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel'
      }).then(async (val) => {
        if (val.value === true) {
          this.tippyInstance.hide()
          try {
            await monitorsApi.multipleDelete(this.selectedMonitors.map(item => item.id))
            this.$emit('delete')
            this.action = 'deleted'
            this.showSuccessMessage()
          } catch (e) {
            this.showErrorMessage()
          }
        }
      })
    },
    async resetMonitors () {
      swal.fire({
        html: '<h4>' + 'Are you sure that you want to reset the selected monitors?</h4>',
        title: '',
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        showCancelButton: true,
        confirmButtonColor: '#eb5757',
        cancelButtonColor: '#9ca3a1',
        confirmButtonText: 'Reset',
        cancelButtonText: 'Cancel'
      }).then(async (val) => {
        if (val.value === true) {
          this.tippyInstance.hide()
          try {
            await monitorsApi.multipleResetStats(this.selectedMonitors.map(item => item.id))
            this.$emit('reset')
            this.action = 'reset'
            this.showSuccessMessage()
          } catch (e) {
            this.showErrorMessage()
          }
        }
      })
    },

    showSuccessMessage () {
      swal.fire({
        title: 'Success',
        text: 'The monitors were successfully ' + this.action,
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        confirmButtonColor: '#6652e0',
        allowOutsideClick: false
      })
    },

    showSuccessNotificationMessage (message) {
      swal.fire({
        title: 'Success',
        text: message,
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        confirmButtonColor: '#6652e0',
        allowOutsideClick: false
      })
    },

    showErrorMessage () {
      swal.fire({
        title: 'Oops',
        text: 'Something went wrong ',
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        allowOutsideClick: true
      })
    },

    showErrorNotificationMessage (message) {
      swal.fire({
        title: 'Oops',
        text: message,
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        allowOutsideClick: true,
        confirmButtonColor: '#6652e0'
      })
    },

    showHtmlErrorNotificationMessage (message, hyperlink = null) {
      swal.fire({
        title: 'Oops',
        html: hyperlink
          ? `The following monitor exceeded the maximum number of entries: <a href="${hyperlink}" target="_blank">${message}</a>`
          : message,
        icon: 'error',
        confirmButtonText: 'OK',
        allowOutsideClick: true,
        confirmButtonColor: '#6652e0'
      })
    },

    openTagsModal () {
      this.tippyInstance.hide()
      this.$modal.show('monitorTags', { monitors: this.selectedMonitors })
    },

    async email () {
      const { value: email } = await swal.fire({
        title: 'Enter Email Address',
        input: 'email',
        inputPlaceholder: 'Enter the recipient email',
        showCancelButton: true,
        confirmButtonColor: '#6652e0',
        confirmButtonText: 'Send',
        cancelButtonText: 'Cancel',
        inputValidator: (value) => {
          if (!value) {
            return 'Please enter an email address.'
          }
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/ // Basic email regex
          if (!emailRegex.test(value)) {
            return 'Please enter a valid email address.'
          }
        }
      })

      if (email) {
        try {
          this.tippyInstance.hide()
          await emailApi.bulkStore(this.selectedMonitors.map((item) => item.id), email)
          this.showSuccessNotificationMessage('The email was successfully added.')
        } catch (error) {
          if (error.response && error.response.data && error.response.data.errorMonitor && error.response.data.hyperlink) {
            this.showHtmlErrorNotificationMessage(error.response.data.errorMonitor, error.response.data.hyperlink)
          } else {
            this.showErrorNotificationMessage('Something went wrong.')
          }
        }
      }
    },

    smsCalls () {
      swal.fire({
        title: 'Enter Phone Number',
        html: `
          <label for="phone-number">Phone Number (with country code)</label>
          <input id="phone-number" type="text" class="swal2-input" placeholder="+1234567890" aria-label="Enter phone number with country code">
          <label class="swal2-checkbox">
            <input type="checkbox" id="enable-sms" class="enable-sms">
            <span>Enable SMS</span>
          </label>
          <label class="swal2-checkbox">
            <input type="checkbox" id="enable-calls">
            <span>Enable Calls</span>
          </label>
        `,
        confirmButtonColor: '#6652e0',
        confirmButtonText: 'Submit',
        showCancelButton: true,
        preConfirm: () => {
          const phoneNumber = document.querySelector('#phone-number')?.value?.trim() || ''
          const enableCalls = document.querySelector('#enable-calls')?.checked || false
          const enableSms = document.querySelector('.enable-sms')?.checked || false
          console.log('calls: ' + enableCalls)
          console.log('sms: ' + enableSms)

          // Validation
          if (!this.validatePhoneNumber(phoneNumber)) {
            swal.showValidationMessage('Invalid phone number. Please include the country code (e.g., +1234567890).')
            return false
          }
          if (!enableCalls && !enableSms) {
            swal.showValidationMessage('Please select at least one option (Calls or SMS).')
            return false
          }
          return { phoneNumber, enableCalls, enableSms }
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const { phoneNumber, enableCalls, enableSms } = result.value
          console.log('Enable Calls:', enableCalls)
          console.log('Enable SMS:', enableSms)
          this.addPhoneNumber(phoneNumber, enableCalls, enableSms)
        }
      })
    },

    addPhoneNumber (phoneNumber, enableCalls, enableSms) {
      phoneNumbersApi.bulkAddPhoneNumber(this.selectedMonitors.map((item) => item.id), phoneNumber, enableCalls, enableSms)
        .then(() => {
          this.showSuccessNotificationMessage('Phone number added successfully!')
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.errorMonitor && error.response.data.hyperlink) {
            this.showHtmlErrorNotificationMessage(error.response.data.errorMonitor, error.response.data.hyperlink)
          } else {
            this.showErrorNotificationMessage('Something went wrong.')
          }
        })
    },

    validatePhoneNumber (phone) {
      const phoneRegex = /^\+\d{1,3}\d{7,15}$/ // Example: +1234567890
      return phoneRegex.test(phone)
    },

    validateUrl (url) {
      const urlRegex = /^(https:\/\/.*)/ // Simple check for a URL format
      if (!urlRegex.test(url)) {
        return 'Please enter a valid URL.'
      }
      return undefined // Explicitly return undefined for valid URLs
    },

    async slack () {
      const { value: slackWebhookUrl } = await swal.fire({
        title: 'Enter Slack Webhook URL',
        input: 'url',
        inputPlaceholder: 'Enter Slack Webhook URL',
        showCancelButton: true,
        confirmButtonColor: '#6652e0',
        confirmButtonText: 'Submit',
        cancelButtonText: 'Cancel',
        inputValidator: (value) => {
          if (!value) {
            return 'Please enter a Slack Webhook URL.'
          }
          return this.validateUrl(value)
        }
      })

      if (slackWebhookUrl) {
        try {
          await slackApi.bulkStoreWebhook(this.selectedMonitors.map((item) => item.id), slackWebhookUrl)
          this.showSuccessNotificationMessage('Slack Webhook URL added successfully.')
        } catch (error) {
          if (error.response && error.response.data && error.response.data.errorMonitor && error.response.data.hyperlink) {
            this.showHtmlErrorNotificationMessage(error.response.data.errorMonitor, error.response.data.hyperlink)
          } else {
            this.showErrorNotificationMessage('Something went wrong.')
          }
        }
      }
    },

    async twilioSms () {
      const { value: twilioPhoneNumber } = await swal.fire({
        title: 'Enter Twilio Phone Number',
        input: 'tel',
        inputPlaceholder: '+1234567890',
        showCancelButton: true,
        confirmButtonColor: '#6652e0',
        confirmButtonText: 'Submit',
        cancelButtonText: 'Cancel',
        inputValidator: (value) => {
          if (!this.validatePhoneNumber(value)) {
            return 'Invalid phone number. Please include the country code (e.g., +1234567890).'
          }
        }
      })

      if (twilioPhoneNumber) {
        try {
          await smsApi.bulkAddPhoneNumber(this.selectedMonitors.map((item) => item.id), twilioPhoneNumber)
          this.showSuccessNotificationMessage('Twilio phone number added successfully.')
        } catch (error) {
          if (error.response && error.response.data && error.response.data.errorMonitor && error.response.data.hyperlink) {
            this.showHtmlErrorNotificationMessage(error.response.data.errorMonitor, error.response.data.hyperlink)
          } else {
            this.showErrorNotificationMessage('Something went wrong. Make sure you are connected to your twilio account on any monitor.')
          }
        }
      }
    },

    async twilioVoice () {
      const { value: twilioVoicePhoneNumber } = await swal.fire({
        title: 'Enter Twilio Phone Number',
        input: 'url',
        inputPlaceholder: 'Enter Twilio Phone Number',
        showCancelButton: true,
        confirmButtonColor: '#6652e0',
        confirmButtonText: 'Submit',
        cancelButtonText: 'Cancel',
        inputValidator: (value) => {
          if (!this.validatePhoneNumber(value)) {
            return 'Invalid phone number. Please include the country code (e.g., +1234567890).'
          }
        }
      })

      if (twilioVoicePhoneNumber) {
        try {
          await voiceApi.bulkAddPhoneNumber(this.selectedMonitors.map((item) => item.id), twilioVoicePhoneNumber)
          this.showSuccessNotificationMessage('Twilio Voice URL added successfully.')
        } catch (error) {
          if (error.response && error.response.data && error.response.data.errorMonitor && error.response.data.hyperlink) {
            this.showHtmlErrorNotificationMessage(error.response.data.errorMonitor, error.response.data.hyperlink)
          } else {
            this.showErrorNotificationMessage('Something went wrong. Make sure you are connected to your twilio account on any monitor.')
          }
        }
      }
    },

    async webhook () {
      const { value: webhookUrl } = await swal.fire({
        title: 'Enter Webhook URL',
        input: 'url',
        inputPlaceholder: 'Enter Webhook URL',
        showCancelButton: true,
        confirmButtonColor: '#6652e0',
        confirmButtonText: 'Submit',
        cancelButtonText: 'Cancel',
        inputValidator: (value) => {
          if (!value) {
            return 'Please enter a Webhook URL.'
          }
          return this.validateUrl(value)
        }
      })

      if (webhookUrl) {
        try {
          await webhookApi.bulkAddWebhook(this.selectedMonitors.map((item) => item.id), webhookUrl)
          this.showSuccessNotificationMessage('Webhook URL added successfully.')
        } catch (error) {
          if (error.response && error.response.data && error.response.data.errorMonitor && error.response.data.hyperlink) {
            this.showHtmlErrorNotificationMessage(error.response.data.errorMonitor, error.response.data.hyperlink)
          } else {
            this.showErrorNotificationMessage('Something went wrong.')
          }
        }
      }
    },

    async signl4 () {
      const { value: signl4Url } = await swal.fire({
        title: 'Enter Signl4 Webhook URL',
        input: 'url',
        inputPlaceholder: 'Enter Signl4 Webhook URL',
        showCancelButton: true,
        confirmButtonColor: '#6652e0',
        confirmButtonText: 'Submit',
        cancelButtonText: 'Cancel',
        inputValidator: (value) => {
          if (!value) {
            return 'Please enter a Signl4 Webhook URL.'
          }
          return this.validateUrl(value)
        }
      })

      if (signl4Url) {
        try {
          await signl4Api.bulkStore(this.selectedMonitors.map((item) => item.id), signl4Url)
          this.showSuccessNotificationMessage('Signl4 Webhook URL added successfully.')
        } catch (error) {
          if (error.response && error.response.data && error.response.data.errorMonitor && error.response.data.hyperlink) {
            this.showHtmlErrorNotificationMessage(error.response.data.errorMonitor, error.response.data.hyperlink)
          } else {
            this.showErrorNotificationMessage('Something went wrong.')
          }
        }
      }
    },

    async discord () {
      const { value: discordWebhookUrl } = await swal.fire({
        title: 'Enter Discord Webhook URL',
        input: 'url',
        inputPlaceholder: 'Enter Discord Webhook URL',
        showCancelButton: true,
        confirmButtonColor: '#6652e0',
        confirmButtonText: 'Submit',
        cancelButtonText: 'Cancel',
        inputValidator: (value) => {
          if (!value) {
            return 'Please enter a Discord Webhook URL.'
          }
          return this.validateUrl(value)
        }
      })

      if (discordWebhookUrl) {
        try {
          await discordApi.bulkStore(this.selectedMonitors.map((item) => item.id), discordWebhookUrl)
          this.showSuccessNotificationMessage('Discord Webhook URL added successfully.')
        } catch (error) {
          if (error.response && error.response.data && error.response.data.errorMonitor && error.response.data.hyperlink) {
            this.showHtmlErrorNotificationMessage(error.response.data.errorMonitor, error.response.data.hyperlink)
          } else {
            this.showErrorNotificationMessage('Something went wrong.')
          }
        }
      }
    },

    async teams () {
      const { value: teamsWebhookUrl } = await swal.fire({
        title: 'Enter Teams Webhook URL',
        input: 'url',
        inputPlaceholder: 'Enter Teams Webhook URL',
        showCancelButton: true,
        confirmButtonColor: '#6652e0',
        confirmButtonText: 'Submit',
        cancelButtonText: 'Cancel',
        inputValidator: (value) => {
          if (!value) {
            return 'Please enter a Teams Webhook URL.'
          }
          return this.validateUrl(value)
        }
      })

      if (teamsWebhookUrl) {
        try {
          await msTeamsApi.bulkStore(this.selectedMonitors.map((item) => item.id), teamsWebhookUrl)
          this.showSuccessNotificationMessage('Teams Webhook URL added successfully.')
        } catch (error) {
          if (error.response && error.response.data && error.response.data.errorMonitor && error.response.data.hyperlink) {
            this.showHtmlErrorNotificationMessage(error.response.data.errorMonitor, error.response.data.hyperlink)
          } else {
            this.showErrorNotificationMessage('Something went wrong.')
          }
        }
      }
    },

    async telegram () {
      // Generate hash for selected monitors
      const hash = this.selectedMonitors.map(monitor => monitor.telegram_hash).join(',')
      const encodedHash = encodeURIComponent(hash)

      // Base64 encode the URL-encoded hash
      let base64Hash = btoa(encodedHash)

      // Make it URL-safe by replacing `+` with `-` and `/` with `_`
      // Remove padding (`=`) as it's not necessary for URL-safe Base64
      base64Hash = base64Hash.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')

      const groupLink = `https://t.me/${botId}?startgroup=${base64Hash}`
      const privateLink = `https://t.me/${botId}?start=${base64Hash}`

      await swal.fire({
        title: 'Telegram Notifications',
        html: `
          <a href="${groupLink}" target="_blank" class="swal2-confirm swal2-styled" style="display: block; margin-bottom: 10px; background: #6652e0; padding: 10px; text-align: center; color: white;">
            Add to a telegram group
          </a>
           <p style="margin-bottom: 15px">If you want to add notifications to a <strong>telegram group</strong>, for example your team chat.</p>
          <a href="${privateLink}" target="_blank" class="swal2-confirm swal2-styled" style="display: block; background: #6652e0; padding: 10px; text-align: center; color: white;">
            Add to a private chat
          </a>
          <p>If you want to add notifications to a <strong>private chat</strong>, meaning the bot will notify only you.</p>
        `,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: 'Close',
        cancelButtonColor: '#d33'
      })
    }
  },

  computed: {
    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .MonitorActions {
    width: 147px;

    &-toolbar {
      position: sticky;
      bottom: 0;
      background: rgba(245, 248, 249, 0.9);
      backdrop-filter: blur(3px);
      padding: 15px 0;
    }

    .button {
      width: 100%;

      ::v-deep {
        svg {
          color: white;
          order: 1;
          margin-right: 0 !important;
          margin-left: 0.5rem;
        }
      }
    }

    .nav {
      width: 135px;

      .nav-item {
        display: block;
        padding: 8px 10px;
        color: map-get($colors, gray-1);
        font-size: 15px;
        text-decoration: none;
        font-weight: 500;
        border-radius: 4px;

        &:hover {
          background-color: map-get($colors, purple-1);
          color: #fff;
        }
      }

      .spacer {
        margin: 10px 8px;
        border-bottom: 1px solid rgba(0,8,16,.15);
      }
    }

    .subscription-expired {
      padding: 4px 8px 8px 8px;
      font-size: 12px;
      color: #777;
    }
  }
</style>
