export default {

  addPhoneNumber (monitor_id, data) {
    return axios.post(`/monitors/${monitor_id}/notifications/add-phone-number`, data)
  },

  updatePhoneNumber (monitor_id, data) {
    return axios.patch(`/monitors/${monitor_id}/notifications/update-phone-number`, data)
  },

  deletePhoneNumber (phoneNumber_id) {
    return axios.delete(`/monitors/${phoneNumber_id}/notifications/delete-phone-number`)
  },

  testPhoneNumber (phoneNumberId) {
    return axios.post(`/monitors/${phoneNumberId}/notifications/test-phone-number`)
  },

  generalAddPhoneNumber (data) {
    return axios.post(`/monitors/general/notifications/add-phone-number`, data)
  },

  updateGeneralPhoneNumberNumber (data) {
    return axios.post('/monitors/general/notifications/update', data)
  },

  testGeneralPhoneNumber (data) {
    return axios.post(`/monitors/general/notifications/test-phone-number`, data)
  },

  bulkAddPhoneNumber (monitors, phoneNumber, enableCalls, enableSms) {
    return axios.post(`/monitors/bulk/notifications/add-phone-number`, { monitors, phoneNumber, enableCalls, enableSms })
  }
}
