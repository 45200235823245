export default {
  /**
   * Store webhook.
   *
   * @param monitorId
   * @param webhook
   * @returns Promise
   */
  async store (monitorId, webhook) {
    return axios.post(`/monitors/${monitorId}/notifications/discord-webhook`, {
      webhook
    })
  },

  /**
   * Send test online request.
   *
   * @param monitorId
   * @returns Promise
   */
  async sendTestOnlineRequest (monitorId) {
    const response = await axios.post(`/monitors/${monitorId}/notifications/discord-webhook/send-online-request`)

    return response.data.success
  },

  /**
   * Send test offline request.
   *
   * @param monitorId
   * @returns Promise
   */
  async sendTestOfflineRequest (monitorId) {
    const response = await axios.post(`/monitors/${monitorId}/notifications/discord-webhook/send-offline-request`)

    return response.data.success
  },

  /**
   * Send ssl expires soon request.
   *
   * @param monitorId
   * @returns Promise
   */
  async sendTestSslExpiresSoonRequest (monitorId) {
    const response = await axios.post(`/monitors/${monitorId}/notifications/discord-webhook/send-ssl-expires-soon-request`)

    return response.data.success
  },

  async generalStore (webhook) {
    return axios.post(`/monitors/general/notifications/discord-webhook`, {
      webhook
    })
  },

  async bulkStore (monitors, webhook) {
    return axios.post(`/monitors/bulk/notifications/discord-webhook`, {
      monitors,
      webhook
    })
  }
}
