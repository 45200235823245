export default {
  /**
   * Add phone number.
   *
   * @param monitor_id
   * @param phone_number
   * @param general
   * @returns Promise
   */
  addPhoneNumber (monitor_id, phone_number) {
    return axios.post(`/monitors/${monitor_id}/notifications/voice/`, {
      phone_number
    })
  },

  testVoice (monitor_id, phone_number) {
    return axios.post(`/monitors/${monitor_id}/notifications/test-voice/`, {
      phone_number
    })
  },

  generalAddPhoneNumber (phone_number) {
    return axios.post(`/monitors/general/notifications/voice/`, {
      phone_number
    })
  },

  bulkAddPhoneNumber (monitors, phone_number) {
    return axios.post(`/monitors/bulk/notifications/voice/`, {
      monitors,
      phone_number
    })
  }
}
